import React, {useState, useEffect} from "react"
import styles from "../endlesss.module.scss"


const Info = ({openInfo}) => {
  
  const closeInfo = () => {
    openInfo(false);
  };
  
  const [infoFade, setInfoFade] = useState(true)
  useEffect(() => {
    const timeId = setTimeout(() => {
      setInfoFade(false)
    }, 500)
    return () => {
      clearTimeout(timeId)
    }
  }, []);


  const [buttonFade, setButtonFade] = useState(true)
  useEffect(() => {
    const timeId = setTimeout(() => {
      setButtonFade(false)
    }, 1000)
    return () => {
      clearTimeout(timeId)
    }
  }, []);
  

  return (
    <>
    <button className={styles.closeInfoButton} onClick={closeInfo}
      style={{
        transition: "all .6s ease-in-out",
        opacity: buttonFade ? 0 : 1,}} 
      > Close </button>
    <div 
    className={styles.infoPanelInner}
    style={{
      transition: "all .6s ease-in-out",
      opacity: infoFade ? 0 : 1,}} 
    >
    <h2>What happens when play and community&nbsp;collide?</h2>
    <p>This collaboration between <a href="https://zeusjones.com/" target="_blank" rel="noreferer noreferrer">Zeus Jones</a>, record producer <a href="https://www.doomtree.net/lazerbeak/" target="_blank" rel="noreferer noreferrer">Lazerbeak</a>, and Web3 music platform <a href="https://endlesss.fm/" target="_blank" rel="noreferer noreferrer">Endlesss</a> is an attempt to answer that question.</p>
    <p>Together with the Endlesss community we created four jams, each based on a different theme of joyful resilience discussed in <a href="https://zeusjones.com/athena">Issue 04 of Athena</a>. Jams are user-generated evolving soundscapes hosted on the Endlesss platform and open to contributions by music makers from all over the world. We invited anyone to participate in our four jams and creatively contribute based on that jam's particular theme. After a week Endlesss members curated their favorite moments from each jam and passed them off to Lazerbeak to play with and remix.</p>
    <p>The result: the four tracks presented here—each with their own listening room. Each room features unique artwork with integrated commentary from Lazerbeak and the Endlesss community.
    </p>
    <p>Each piece of music is an invitation—for the Endlesss community, for Lazerbeak, and for ourselves—to celebrate and reflect. What does it sound like to play without restriction? To be who you really are? To be in the moment? To become whole?</p>
    
    <h3>Credits</h3>

    <h4><b>P</b>lay Ti<b>m</b>e</h4>

    <ul>
    <li>firephly</li>
    <li>feloniousmonk</li>
    <li>thevoidinclusive</li>
    <li>oddstar</li>
    <li>littlewing</li>
    <li>caerickson</li>
    <li>njlang</li>
    <li>badrico</li>
    <li>lwlkc</li>
    <li>applejaxsx</li>
    <li>winkle89</li>
    <li>paul_cine</li>
    <li>mng</li>
    <li>discopas</li>
    <li>awro</li>
    <li>redskylullaby</li>
    <li>Remixed and produced by Lazerbeak</li>
    </ul>

    <h4><em>R</em>adi<b>c</b>al H<em>o</em>nes<b>t</b>y</h4>
    <ul>
      <li>thevoidinclusive</li>
      <li>feloniousmonk</li>
      <li>firephly</li>
      <li>njlang</li>
      <li>lwlkc</li>
      <li>applejaxsx</li>
      <li>paul_cine</li>
      <li>mng</li>
      <li>discopas</li>
      <li>slowgaffle</li>
      <li>peteidm</li>
      <li>sonicadventure</li>
      <li>fancyspectacles</li>
      <li>deadkousin</li>
      <li>Remixed and produced by Lazerbeak</li>
    </ul>

    <h4>S<em>o</em>ni<b>c</b> Ser<em>o</em>to<b>n</b>in</h4>
      <ul>
        <li>oddstar</li>
        <li>feloniousmonk</li>
        <li>thevoidinclusive</li>
        <li>firephly</li>
        <li>caerickson</li>
        <li>njlang</li>
        <li>badrico</li>
        <li>lwlkc</li>
        <li>applejaxsx</li>
        <li>discopas</li>
        <li>katinkera</li>
        <li>redskylullaby</li>
        <li>gmclee</li>
        <li>Remixed and produced by Lazerbeak</li>
      </ul>
        
      <h4>H<b>u</b>ma<b>n</b>izer</h4>
      <ul>
        <li>feloniousmonk</li>
        <li>thevoidinclusive</li>
        <li>firephly</li>
        <li>sonic adventure</li>
        <li>njlang</li>
        <li>katinkera</li>
        <li>ad2um</li>
        <li>paul_cine</li>
        <li>sadza</li>
        <li>badrico</li>
        <li>lwlkc</li>
        <li>deadkousin</li>
        <li>omnijess</li>
        <li>applejaxsx</li>
        <li>discopas</li>
        <li>awro</li>
        <li>mng</li>
        <li>billsybainbridge</li>
        <li>honeydisco</li>
        <li>fry</li>
        <li>ashtonmaxwell</li>
        <li>cng303</li>
        <li>frostbitemixes</li>
        <li>goodmolecule</li>
        <li>pandaneptuniano</li>
        <li>fatribz</li>
        <li>redskylullaby</li>
        <li>dsorce</li>
        <li>Remixed and produced by Lazerbeak</li>
      </ul>
      
      <p style={{marginTop: '40px'}}>Concepted and created by Zeus Jones:</p>
      
      <ul>
        <li><a target="_blank" rel="noreferer noreferrer" href="https://zeusjones.com/people/alex-register">Alex Register</a></li>
        <li><a target="_blank" rel="noreferer noreferrer" href="https://zeusjones.com/people/christian-erickson">Christian Erickson</a></li>
        <li><a target="_blank" rel="noreferer noreferrer" href="https://zeusjones.com/people/dustin-studelska">Dustin Studelska</a></li>
        <li><a target="_blank" rel="noreferer noreferrer" href="https://zeusjones.com/people/denzel-boyd">Denzel Boyd</a></li>
        <li><a target="_blank" rel="noreferer noreferrer" href="https://zeusjones.com/people/morgan-hay-chapman">Morgan Hay-Chapman</a></li>
        <li><a target="_blank" rel="noreferer noreferrer" href="https://zeusjones.com/people/sam-stanfield">Sam Stanfield</a></li>
        <li><a target="_blank" rel="noreferer noreferrer" href="https://zeusjones.com/people/sara-ritten">Sara Ritten</a></li>
      </ul>
    </div>
    </>
  )
}
export default Info
