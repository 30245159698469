import React, { useCallback, useEffect, useRef, useState } from "react";
import { Swiper, } from "swiper/react";
import { SwiperSlide } from 'swiper/react/swiper-react.js';

import { EffectCoverflow} from "swiper";
import styles from '../endlesss.module.scss'
import './useFade.css'

import RadicalVimeo from "./radicalVimeo";
import PlayVimeo from "./playVimeo";
import HumanVimeo from "./humanVimeo";
import SonicVimeo from "./sonicVimeo";

import './slider.scss'
import 'swiper/swiper.min.css'
import 'swiper/modules/effect-coverflow/effect-coverflow.min.css'

import play from '../../../../images/athena-04-resilience/endlesss/playtime.jpg'
import playGif from '../../../../images/athena-04-resilience/endlesss/playtimegif.gif'

import human from '../../../../images/athena-04-resilience/endlesss/human.jpg'
import humanGif from '../../../../images/athena-04-resilience/endlesss/humangif.gif'

import radical from '../../../../images/athena-04-resilience/endlesss/radical.jpg'
import radicalGif from '../../../../images/athena-04-resilience/endlesss/radical.gif'

import sonic from '../../../../images/athena-04-resilience/endlesss/sonic.jpg'
import sonicGif from '../../../../images/athena-04-resilience/endlesss/sonicgif.gif'

import prevBtn from '../../../../images/athena-04-resilience/endlesss/prev.png'
import nextBtn from '../../../../images/athena-04-resilience/endlesss/next.png'

import playbtn from '../../../../images/athena-04-resilience/endlesss/play.png'
import pausebtn from '../../../../images/athena-04-resilience/endlesss/pause.png'

const CustomSwiper = (props) => {

  const {openInfo, toggleInfo, slideOpen} = props;

  const swiperRef = useRef(null);
  const radicalVideo = useRef();
  const playVideo = useRef();
  const humanVideo = useRef();
  const sonicVideo = useRef();


  const [hover, setHover] = useState(false);

  const [hoveredNext, setHoveredNext] = useState(false);
  const [hoveredPrev, setHoveredPrev] = useState(false);

  const [leftButton, setLeftButton] = useState(false)
  const [centerButton, setCenterButton] = useState(false)
  const [rightButton, setRighttButton] = useState(false)
  
  const [expandSlider, setExpandSlider] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)


  const isHover = () => {
    setHover(true)
  }
  const isNotHover = () => {
    setHover(false)
  }
  const resumeVid = () => {
    setIsPlaying(true)
    if (radicalVideo.current)(
      radicalVideo.current.resumeVid()
    )
    if (playVideo.current)(
      playVideo.current.resumeVid()
    )
    if (humanVideo.current)(
      humanVideo.current.resumeVid()
    )
    if (sonicVideo.current)(
      sonicVideo.current.resumeVid()
    )
  }
  const pauseVid = () => {
    setIsPlaying(false)
    if (radicalVideo.current)(
      radicalVideo.current.pauseVid()
    )
    if (playVideo.current)(
      playVideo.current.pauseVid()
    )
    if (humanVideo.current)(
      humanVideo.current.pauseVid()
    )
    if (sonicVideo.current)(
      sonicVideo.current.pauseVid()
    )
  }
  const openModal = () => {
    setIsPlaying(true)
    if (radicalVideo.current)(
      radicalVideo.current.startPlaying()
    )
    if (playVideo.current)(
      playVideo.current.startPlaying()
    )
    if (humanVideo.current)(
      humanVideo.current.startPlaying()
    )
    if (sonicVideo.current)(
      sonicVideo.current.startPlaying()
    )
    setModalOpen(true)
    slideOpen(true)
    setTimeout(() => {
      setExpandSlider(true);
    }, 500);
    setTimeout(() => {
      setLeftButton(true);
    }, 1000);
    setTimeout(() => {
      setCenterButton(true);
    }, 1100);
    setTimeout(() => {
      setRighttButton(true);
    }, 1200);
  }
  const closeModal = () => {
    if (radicalVideo.current)(
      radicalVideo.current.stopPlaying()
    )
    if (playVideo.current)(
      playVideo.current.stopPlaying()
    )
    if (humanVideo.current)(
      humanVideo.current.stopPlaying()
    )
    if (sonicVideo.current)(
      sonicVideo.current.stopPlaying()
    )
    setIsPlaying(false)
    setExpandSlider(false)
    setModalOpen(false)
    setLeftButton(false)
    setCenterButton(false)
    setRighttButton(false)
    slideOpen(false)
  }
  const [chatIsActive, setChatActive] = useState(true)
  const sendDataToParent = (dat) => {
    setChatActive(dat)
  }
  const [transitionEnded, setTransitionEnded] = useState(false)
  const closedStyles = {
    position: "absolute",
    width: '100%',
  }
  const openStyles = {
    position: 'fixed',
    height: '100vh',
    width: '100%',
  }
  const videoContainerOnOpen = {
      //position: expandSlider ? 'absolute' : 'absolute',
      position: 'fixed',
      top: '0',
      bottom: '0',
      margin: 'auto',
      transform: modalOpen ? 'scale(1)' : 'scale(0.8)',
      opacity: modalOpen ? '1' : '0',
      zIndex: modalOpen ? '800' : '-1',
      height: modalOpen ? '100vh' : '100%',
      width: modalOpen ? '100%' : '100%',
      overflow: 'hidden',
      transition: 'transform .25s ease'
  }
  
  const handlePrev = useCallback(() => {
    if (!swiperRef.current) return;
    swiperRef.current.slidePrev();
    setIsPlaying(false)
    // if (radicalVideo.current)(
    //   radicalVideo.current.stopPlaying()
    // )
    // if (playVideo.current)(
    //   playVideo.current.stopPlaying()
    // )
    // if (humanVideo.current)(
    //   humanVideo.current.stopPlaying()
    // )
  }, []);

  const handleNext = useCallback(() => {
    if (!swiperRef.current) return;
    swiperRef.current.slideNext();
    setIsPlaying(false)
    // if (radicalVideo.current)(
    //   radicalVideo.current.stopPlaying()
    // )
    // if (playVideo.current)(
    //   playVideo.current.stopPlaying()
    // )
    // if (humanVideo.current)(
    //   humanVideo.current.stopPlaying()
    // )
  }, []);

  return (
    <>
      <Swiper
        modules={[EffectCoverflow]}
        speed={1000}        
        effect={'coverflow'}
        spaceBetween={0}
        className={expandSlider ? 'open-swiper' : 'closed-swiper'}
        style={modalOpen ? openStyles : closedStyles}
        coverflowEffect={{
          stretch: 0,
          rotate:  45,
          depth: 50,
          modifier: 1,
          slideShadows: false
        }}
        onTransitionEnd={() => (
          setTransitionEnded(true)
        )}
        loop={true}
        centeredSlides={true}
        slidesPerView={modalOpen ? 1 : 2}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
      > 
        <SwiperSlide>
          {({ isActive, isPrev, isNext }) => (
            <div style={{ 
              position: 'relative',
              height: '100%',
              // backgroundImage: modalOpen ? `` : `url(${radical})`,
              backgroundImage: `url(${radical})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              transition: 'transform 0.3s',
              transform: 
                isActive && !isPrev && !isNext && !modalOpen ? 'translateX(0) scale(0.8)' :
                isActive && !hoveredNext && !hoveredPrev && !modalOpen ? 'translateX(0) scale(0.8)' :
                !isActive && isPrev && hoveredPrev ? 'translateX(2%) scale(1.01)' : 
                !isActive && isNext && hoveredNext ? 'translateX(-2%) scale(1.01)' : 
                'translateX(0) scale(1)'
            }}
            >
            <div 
            className={expandSlider ? 'full-screen' : 'slide-inner'}
            onMouseEnter={isActive ? isHover : undefined}
            onMouseLeave={isActive ? isNotHover : undefined}
            onClick={isActive ? openModal : undefined}
            style={{ 
              backgroundImage: isActive && !modalOpen ? `url(${radicalGif})` : '',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              transition: 'transform 0.3s',
            }}>
              {!modalOpen && 
                <h2 
                className="hovered"
                style={{ opacity: isActive ? '1' : '0', transition: '0.6s' }}>
                  <em>R</em>adi<b>c</b>al H<em>o</em>nes<b>t</b>y
                </h2>
              }
              <div style={videoContainerOnOpen}>
                {isActive &&
                  <RadicalVimeo
                    ref={radicalVideo}
                    transitionEnded={transitionEnded}
                    isActive={isActive}
                    sendDataToParent={sendDataToParent}
                    toggleInfo={toggleInfo} 
                    modalOpen={modalOpen} 
                  />
                }
              </div>
            </div>
            </div>
            
          )}
        </SwiperSlide>
        <SwiperSlide>
          {({ isActive, isPrev, isNext }) => (
            <div style={{ 
              position: 'relative',
              height: '100%',
              backgroundImage: `url(${play})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              transition: 'transform 0.3s',
              transform: 
                isActive && !isPrev && !isNext && !modalOpen ? 'translateX(0) scale(0.8)' :
                isActive && !hoveredNext && !hoveredPrev && !modalOpen ? 'translateX(0) scale(0.8)' :
                !isActive && isPrev && hoveredPrev ? 'translateX(2%) scale(1.01)' : 
                !isActive && isNext && hoveredNext ? 'translateX(-2%) scale(1.01)' : 
                'translateX(0) scale(1)'
            }}
            >
            <div 
            className={expandSlider ? 'full-screen' : 'slide-inner'}
            onMouseEnter={isActive ? isHover : undefined}
            onMouseLeave={isActive ? isNotHover : undefined}
            onClick={isActive ? openModal : undefined}
            style={{ 
              backgroundImage: isActive && !modalOpen ? `url(${playGif})` : '',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              transition: 'transform 0.3s',
            }}>
            {!modalOpen && 
                <h2 className="hovered" style={{ opacity: isActive ? '1' : '0', transition: '0.6s' }}>
                  <b>P</b>lay Ti<b>m</b>e
                </h2>
              }
                <div style={videoContainerOnOpen}>
                  {isActive &&
                  <PlayVimeo
                    transitionEnded={transitionEnded}
                    isActive={isActive}
                    sendDataToParent={sendDataToParent}
                    ref={playVideo}
                    toggleInfo={toggleInfo} 
                    modalOpen={modalOpen} 
                  />
                  }
                </div>
            </div>
            </div>
            
          )}
        </SwiperSlide>
        <SwiperSlide>
          {({ isActive, isPrev, isNext }) => (
            <div style={{ 
              position: 'relative',
              height: '100%',
              backgroundImage: `url(${human})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              transition: 'transform 0.3s',
              transform: 
                isActive && !isPrev && !isNext && !modalOpen ? 'translateX(0) scale(0.8)' :
                isActive && !hoveredNext && !hoveredPrev && !modalOpen ? 'translateX(0) scale(0.8)' :
                !isActive && isPrev && hoveredPrev ? 'translateX(2%) scale(1.01)' : 
                !isActive && isNext && hoveredNext ? 'translateX(-2%) scale(1.01)' : 
                'translateX(0) scale(1)'
            }}
            >
            <div 
            className={expandSlider ? 'full-screen' : 'slide-inner'}
            onMouseEnter={isActive ? isHover : undefined}
            onMouseLeave={isActive ? isNotHover : undefined}
            onClick={isActive ? openModal : undefined}
            style={{ 
              backgroundImage: isActive && !modalOpen ? `url(${humanGif})` : '',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              transition: 'transform 0.3s',
            }}>
              {!modalOpen && 
                  <h2 className="hovered"
                  style={{ opacity: isActive ? '1' : '0', transition: '0.6s' }}>
                    H<b>u</b>ma<b>n</b>izer
                  </h2>
                }
                <div style={videoContainerOnOpen}>
                  {isActive && 
                    <HumanVimeo 
                      sendDataToParent={sendDataToParent}
                      ref={humanVideo}
                      toggleInfo={toggleInfo} 
                      modalOpen={modalOpen} 
                      transitionEnded={transitionEnded}
                      isActive={isActive}
                    />
                  }
                </div>
            </div>
            </div>
            
          )}
        </SwiperSlide>
        <SwiperSlide>
          {({ isActive, isPrev, isNext }) => (
            <div style={{ 
              position: 'relative',
              height: '100%',
              backgroundImage: `url(${sonic})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              transition: 'transform 0.3s',
              transform: 
                isActive && !isPrev && !isNext && !modalOpen ? 'translateX(0) scale(0.8)' :
                isActive && !hoveredNext && !hoveredPrev && !modalOpen ? 'translateX(0) scale(0.8)' :
                !isActive && isPrev && hoveredPrev ? 'translateX(2%) scale(1.01)' : 
                !isActive && isNext && hoveredNext ? 'translateX(-2%) scale(1.01)' : 
                'translateX(0) scale(1)'
            }}
            >
            <div 
            className={expandSlider ? 'full-screen' : 'slide-inner'}
            onMouseEnter={isActive ? isHover : undefined}
            onMouseLeave={isActive ? isNotHover : undefined}
            onClick={isActive ? openModal : undefined}
            style={{ 
              backgroundImage: isActive && !modalOpen ? `url(${sonicGif})` : '',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              transition: 'transform 0.3s',
            }}>
              {!modalOpen && 
                  <h2 className="hovered"
                  style={{ opacity: isActive ? '1' : '0', transition: '0.6s' }}>
                   S<em>o</em>ni<b>c</b> Ser<em>o</em>to<b>n</b>in
                  </h2>
                }
                <div style={videoContainerOnOpen}>
                  {isActive && 
                    <SonicVimeo 
                      sendDataToParent={sendDataToParent}
                      ref={sonicVideo}
                      toggleInfo={toggleInfo} 
                      modalOpen={modalOpen} 
                      transitionEnded={transitionEnded}
                      isActive={isActive}
                    />
                  }
                </div>
            </div>
            </div>
            
          )}
        </SwiperSlide>
       

        <SwiperSlide>
          {({ isActive, isPrev, isNext }) => (
            <div style={{ 
              position: 'relative',
              height: '100%',
              // backgroundImage: modalOpen ? `` : `url(${radical})`,
              backgroundImage: `url(${radical})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              transition: 'transform 0.3s',
              transform: 
                isActive && !isPrev && !isNext && !modalOpen ? 'translateX(0) scale(0.8)' :
                isActive && !hoveredNext && !hoveredPrev && !modalOpen ? 'translateX(0) scale(0.8)' :
                !isActive && isPrev && hoveredPrev ? 'translateX(2%) scale(1.01)' : 
                !isActive && isNext && hoveredNext ? 'translateX(-2%) scale(1.01)' : 
                'translateX(0) scale(1)'
            }}
            >
            <div 
            className={expandSlider ? 'full-screen' : 'slide-inner'}
            onMouseEnter={isActive ? isHover : undefined}
            onMouseLeave={isActive ? isNotHover : undefined}
            onClick={isActive ? openModal : undefined}
            style={{ 
              backgroundImage: isActive && !modalOpen ? `url(${radicalGif})` : '',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              transition: 'transform 0.3s',
            }}>
              {!modalOpen && 
                <h2 
                className="hovered"
                style={{ opacity: isActive ? '1' : '0', transition: '0.6s' }}>
                  <em>R</em>adi<b>c</b>al H<em>o</em>nes<b>t</b>y
                </h2>
              }
              <div style={videoContainerOnOpen}>
                {isActive &&
                  <RadicalVimeo
                    ref={radicalVideo}
                    transitionEnded={transitionEnded}
                    isActive={isActive}
                    sendDataToParent={sendDataToParent}
                    toggleInfo={toggleInfo} 
                    modalOpen={modalOpen} 
                  />
                }
              </div>
            </div>
            </div>
            
          )}
        </SwiperSlide>
        <SwiperSlide>
          {({ isActive, isPrev, isNext }) => (
            <div style={{ 
              position: 'relative',
              height: '100%',
              backgroundImage: `url(${play})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              transition: 'transform 0.3s',
              transform: 
                isActive && !isPrev && !isNext && !modalOpen ? 'translateX(0) scale(0.8)' :
                isActive && !hoveredNext && !hoveredPrev && !modalOpen ? 'translateX(0) scale(0.8)' :
                !isActive && isPrev && hoveredPrev ? 'translateX(2%) scale(1.01)' : 
                !isActive && isNext && hoveredNext ? 'translateX(-2%) scale(1.01)' : 
                'translateX(0) scale(1)'
            }}
            >
            <div 
            className={expandSlider ? 'full-screen' : 'slide-inner'}
            onMouseEnter={isActive ? isHover : undefined}
            onMouseLeave={isActive ? isNotHover : undefined}
            onClick={isActive ? openModal : undefined}
            style={{ 
              backgroundImage: isActive && !modalOpen ? `url(${playGif})` : '',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              transition: 'transform 0.3s',
            }}>
            {!modalOpen && 
                <h2 className="hovered" style={{ opacity: isActive ? '1' : '0', transition: '0.6s' }}>
                  <b>P</b>lay Ti<b>m</b>e
                </h2>
              }
                <div style={videoContainerOnOpen}>
                  {isActive &&
                  <PlayVimeo
                    transitionEnded={transitionEnded}
                    isActive={isActive}
                    sendDataToParent={sendDataToParent}
                    ref={playVideo}
                    toggleInfo={toggleInfo} 
                    modalOpen={modalOpen} 
                  />
                  }
                </div>
            </div>
            </div>
            
          )}
        </SwiperSlide>
        <SwiperSlide>
          {({ isActive, isPrev, isNext }) => (
            <div style={{ 
              position: 'relative',
              height: '100%',
              backgroundImage: `url(${human})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              transition: 'transform 0.3s',
              transform: 
                isActive && !isPrev && !isNext && !modalOpen ? 'translateX(0) scale(0.8)' :
                isActive && !hoveredNext && !hoveredPrev && !modalOpen ? 'translateX(0) scale(0.8)' :
                !isActive && isPrev && hoveredPrev ? 'translateX(2%) scale(1.01)' : 
                !isActive && isNext && hoveredNext ? 'translateX(-2%) scale(1.01)' : 
                'translateX(0) scale(1)'
            }}
            >
            <div 
            className={expandSlider ? 'full-screen' : 'slide-inner'}
            onMouseEnter={isActive ? isHover : undefined}
            onMouseLeave={isActive ? isNotHover : undefined}
            onClick={isActive ? openModal : undefined}
            style={{ 
              backgroundImage: isActive && !modalOpen ? `url(${humanGif})` : '',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              transition: 'transform 0.3s',
            }}>
              {!modalOpen && 
                  <h2 className="hovered"
                  style={{ opacity: isActive ? '1' : '0', transition: '0.6s' }}>
                    H<b>u</b>ma<b>n</b>izer
                  </h2>
                }
                <div style={videoContainerOnOpen}>
                  {isActive && 
                    <HumanVimeo 
                      sendDataToParent={sendDataToParent}
                      ref={humanVideo}
                      toggleInfo={toggleInfo} 
                      modalOpen={modalOpen} 
                      transitionEnded={transitionEnded}
                      isActive={isActive}
                    />
                  }
                </div>
            </div>
            </div>
            
          )}
        </SwiperSlide>
        <SwiperSlide>
          {({ isActive, isPrev, isNext }) => (
            <div style={{ 
              position: 'relative',
              height: '100%',
              backgroundImage: `url(${sonic})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              transition: 'transform 0.3s',
              transform: 
                isActive && !isPrev && !isNext && !modalOpen ? 'translateX(0) scale(0.8)' :
                isActive && !hoveredNext && !hoveredPrev && !modalOpen ? 'translateX(0) scale(0.8)' :
                !isActive && isPrev && hoveredPrev ? 'translateX(2%) scale(1.01)' : 
                !isActive && isNext && hoveredNext ? 'translateX(-2%) scale(1.01)' : 
                'translateX(0) scale(1)'
            }}
            >
            <div 
            className={expandSlider ? 'full-screen' : 'slide-inner'}
            onMouseEnter={isActive ? isHover : undefined}
            onMouseLeave={isActive ? isNotHover : undefined}
            onClick={isActive ? openModal : undefined}
            style={{ 
              backgroundImage: isActive && !modalOpen ? `url(${sonicGif})` : '',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              transition: 'transform 0.3s',
            }}>
              {!modalOpen && 
                  <h2 className="hovered"
                  style={{ opacity: isActive ? '1' : '0', transition: '0.6s' }}>
                   S<em>o</em>ni<b>c</b> Ser<em>o</em>to<b>n</b>in
                  </h2>
                }
                <div style={videoContainerOnOpen}>
                  {isActive && 
                    <SonicVimeo 
                      sendDataToParent={sendDataToParent}
                      ref={sonicVideo}
                      toggleInfo={toggleInfo} 
                      modalOpen={modalOpen} 
                      transitionEnded={transitionEnded}
                      isActive={isActive}
                    />
                  }
                </div>
            </div>
            </div>
            
          )}
        </SwiperSlide>

        <SwiperSlide>
          {({ isActive, isPrev, isNext }) => (
            <div style={{ 
              position: 'relative',
              height: '100%',
              // backgroundImage: modalOpen ? `` : `url(${radical})`,
              backgroundImage: `url(${radical})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              transition: 'transform 0.3s',
              transform: 
                isActive && !isPrev && !isNext && !modalOpen ? 'translateX(0) scale(0.8)' :
                isActive && !hoveredNext && !hoveredPrev && !modalOpen ? 'translateX(0) scale(0.8)' :
                !isActive && isPrev && hoveredPrev ? 'translateX(2%) scale(1.01)' : 
                !isActive && isNext && hoveredNext ? 'translateX(-2%) scale(1.01)' : 
                'translateX(0) scale(1)'
            }}
            >
            <div 
            className={expandSlider ? 'full-screen' : 'slide-inner'}
            onMouseEnter={isActive ? isHover : undefined}
            onMouseLeave={isActive ? isNotHover : undefined}
            onClick={isActive ? openModal : undefined}
            style={{ 
              backgroundImage: isActive && !modalOpen ? `url(${radicalGif})` : '',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              transition: 'transform 0.3s',
            }}>
              {!modalOpen && 
                <h2 
                className="hovered"
                style={{ opacity: isActive ? '1' : '0', transition: '0.6s' }}>
                  <em>R</em>adi<b>c</b>al H<em>o</em>nes<b>t</b>y
                </h2>
              }
              <div style={videoContainerOnOpen}>
                {isActive &&
                  <RadicalVimeo
                    ref={radicalVideo}
                    transitionEnded={transitionEnded}
                    isActive={isActive}
                    sendDataToParent={sendDataToParent}
                    toggleInfo={toggleInfo} 
                    modalOpen={modalOpen} 
                  />
                }
              </div>
            </div>
            </div>
            
          )}
        </SwiperSlide>
        <SwiperSlide>
          {({ isActive, isPrev, isNext }) => (
            <div style={{ 
              position: 'relative',
              height: '100%',
              backgroundImage: `url(${play})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              transition: 'transform 0.3s',
              transform: 
                isActive && !isPrev && !isNext && !modalOpen ? 'translateX(0) scale(0.8)' :
                isActive && !hoveredNext && !hoveredPrev && !modalOpen ? 'translateX(0) scale(0.8)' :
                !isActive && isPrev && hoveredPrev ? 'translateX(2%) scale(1.01)' : 
                !isActive && isNext && hoveredNext ? 'translateX(-2%) scale(1.01)' : 
                'translateX(0) scale(1)'
            }}
            >
            <div 
            className={expandSlider ? 'full-screen' : 'slide-inner'}
            onMouseEnter={isActive ? isHover : undefined}
            onMouseLeave={isActive ? isNotHover : undefined}
            onClick={isActive ? openModal : undefined}
            style={{ 
              backgroundImage: isActive && !modalOpen ? `url(${playGif})` : '',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              transition: 'transform 0.3s',
            }}>
            {!modalOpen && 
                <h2 className="hovered" style={{ opacity: isActive ? '1' : '0', transition: '0.6s' }}>
                  <b>P</b>lay Ti<b>m</b>e
                </h2>
              }
                <div style={videoContainerOnOpen}>
                  {isActive &&
                  <PlayVimeo
                    transitionEnded={transitionEnded}
                    isActive={isActive}
                    sendDataToParent={sendDataToParent}
                    ref={playVideo}
                    toggleInfo={toggleInfo} 
                    modalOpen={modalOpen} 
                  />
                  }
                </div>
            </div>
            </div>
            
          )}
        </SwiperSlide>
        <SwiperSlide>
          {({ isActive, isPrev, isNext }) => (
            <div style={{ 
              position: 'relative',
              height: '100%',
              backgroundImage: `url(${human})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              transition: 'transform 0.3s',
              transform: 
                isActive && !isPrev && !isNext && !modalOpen ? 'translateX(0) scale(0.8)' :
                isActive && !hoveredNext && !hoveredPrev && !modalOpen ? 'translateX(0) scale(0.8)' :
                !isActive && isPrev && hoveredPrev ? 'translateX(2%) scale(1.01)' : 
                !isActive && isNext && hoveredNext ? 'translateX(-2%) scale(1.01)' : 
                'translateX(0) scale(1)'
            }}
            >
            <div 
            className={expandSlider ? 'full-screen' : 'slide-inner'}
            onMouseEnter={isActive ? isHover : undefined}
            onMouseLeave={isActive ? isNotHover : undefined}
            onClick={isActive ? openModal : undefined}
            style={{ 
              backgroundImage: isActive && !modalOpen ? `url(${humanGif})` : '',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              transition: 'transform 0.3s',
            }}>
              {!modalOpen && 
                  <h2 className="hovered"
                  style={{ opacity: isActive ? '1' : '0', transition: '0.6s' }}>
                    H<b>u</b>ma<b>n</b>izer
                  </h2>
                }
                <div style={videoContainerOnOpen}>
                  {isActive && 
                    <HumanVimeo 
                      sendDataToParent={sendDataToParent}
                      ref={humanVideo}
                      toggleInfo={toggleInfo} 
                      modalOpen={modalOpen} 
                      transitionEnded={transitionEnded}
                      isActive={isActive}
                    />
                  }
                </div>
            </div>
            </div>
            
          )}
        </SwiperSlide>
        <SwiperSlide>
          {({ isActive, isPrev, isNext }) => (
            <div style={{ 
              position: 'relative',
              height: '100%',
              backgroundImage: `url(${sonic})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              transition: 'transform 0.3s',
              transform: 
                isActive && !isPrev && !isNext && !modalOpen ? 'translateX(0) scale(0.8)' :
                isActive && !hoveredNext && !hoveredPrev && !modalOpen ? 'translateX(0) scale(0.8)' :
                !isActive && isPrev && hoveredPrev ? 'translateX(2%) scale(1.01)' : 
                !isActive && isNext && hoveredNext ? 'translateX(-2%) scale(1.01)' : 
                'translateX(0) scale(1)'
            }}
            >
            <div 
            className={expandSlider ? 'full-screen' : 'slide-inner'}
            onMouseEnter={isActive ? isHover : undefined}
            onMouseLeave={isActive ? isNotHover : undefined}
            onClick={isActive ? openModal : undefined}
            style={{ 
              backgroundImage: isActive && !modalOpen ? `url(${sonicGif})` : '',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              transition: 'transform 0.3s',
            }}>
              {!modalOpen && 
                  <h2 className="hovered"
                  style={{ opacity: isActive ? '1' : '0', transition: '0.6s' }}>
                   S<em>o</em>ni<b>c</b> Ser<em>o</em>to<b>n</b>in
                  </h2>
                }
                <div style={videoContainerOnOpen}>
                  {isActive && 
                    <SonicVimeo 
                      sendDataToParent={sendDataToParent}
                      ref={sonicVideo}
                      toggleInfo={toggleInfo} 
                      modalOpen={modalOpen} 
                      transitionEnded={transitionEnded}
                      isActive={isActive}
                    />
                  }
                </div>
            </div>
            </div>
            
          )}
        </SwiperSlide>

      </Swiper>
      {!modalOpen &&
        <>
          <button className="prev-slide" 
            onMouseEnter={() => setHoveredPrev(true)} 
            onMouseLeave={() => setHoveredPrev(false)}
            onClick={() => swiperRef.current?.slidePrev()}> </button>
          <button 
            className="next-slide"
            onMouseEnter={() => setHoveredNext(true)} 
            onMouseLeave={() => setHoveredNext(false)}
            onClick={() => swiperRef.current?.slideNext()}> </button>
        </>
      }

      {modalOpen && chatIsActive && 
        <div className="prev-next-buttons"
          style={{
            opacity: modalOpen ? '1' : '0',
            zIndex: modalOpen ? '1' : '-1',
            transition: 'opacity 0.5s',
          }} 
          >
            <button 
              className="prev"
              // onClick={() => swiperRef.current?.slidePrev()}
              onClick={handlePrev}
              style={{
                opacity: leftButton ? '1' : '0',
                zIndex: leftButton ? '1' : '-1',
                transition: 'opacity 0.5s',
              }} 
            >
            <img src={prevBtn} alt="Previous Button" />
          </button>


            { isPlaying ?
              <button
                style={{
                  opacity: centerButton ? '1' : '0',
                  zIndex: centerButton ? '1' : '-1',
                  transition: 'opacity 0.5s',
                }} 
              className="playPauseButton" onClick={pauseVid}>
                <img src={pausebtn} alt="Pause Button" />
              </button>
            :
              <button 
              style={{
                  opacity: centerButton ? '1' : '0',
                  zIndex: centerButton ? '1' : '-1',
                  transition: 'opacity 0.5s',
                }} 
              className="playPauseButton" onClick={resumeVid}>
                <img src={playbtn} alt="Play Button" />
              </button>
            }

          


          <button 
            className="nxt"
            //onClick={() => swiperRef.current?.slideNext()}
            onClick={handleNext}
            style={{
              opacity: rightButton ? '1' : '0',
              zIndex: rightButton ? '1' : '-1',
              transition: 'opacity 0.5s',
            }} 
            >
              <img src={nextBtn} alt="Next Button" />
            </button>
        </div>
      }
      
        <div className={styles.pageButtons}>
          { modalOpen && !toggleInfo && 
            <button className={styles.goBack} onClick={closeModal}>Go Back</button>
          }
          { !toggleInfo && 
            <button className={styles.buttonInfo} onClick={openInfo}>Info</button>
          }
        </div>
    </>
  );
}
export default CustomSwiper