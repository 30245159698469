import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import Vimeo from '@u-wave/react-vimeo'
import styles from '../endlesss.module.scss'

import Quote from "./quote"

import ProgressBar from './progressBar'
import './useFade.css'

import speaker from '../../../../images/athena-04-resilience/endlesss/speaker.png'
// import playbtn from '../../../../images/athena-04-resilience/endlesss/play.png'
// import pausebtn from '../../../../images/athena-04-resilience/endlesss/pause.png'


const RadicalVimeo = forwardRef((props, radicalVideo) => {
  useImperativeHandle(radicalVideo, () => ({
    startPlaying() {
      setPlaying(true)
    },
    pauseVid() {
      setPlaying(false)
    },
    resumeVid() {
      setPlaying(true)
    },
    stopPlaying() {
      setPlaying(false)
    }
  }))

  const {toggleInfo, modalOpen, sendDataToParent, isActive, transitionEnded} = props;
  const player = useRef(null);

  const [playing, setPlaying] = useState(false);
  const [played, setPlayed] = useState(0);
  const [prog, setProgress] = useState(0);

  const [showOne, setShowOne] = useState(false);
  const [showTwo, setShowTwo] = useState(false);
  const [showThree, setShowThree] = useState(false);
  const [showFour, setShowFour] = useState(false);
  const [showFive, setShowFive] = useState(false);
  const [showSix, setShowSix] = useState(false);
  const [showSeven, setShowSeven] = useState(false);
  const [showEight, setShowEight] = useState(false);
  const [showNine, setShowNine] = useState(false);
  const [showTen, setShowTen] = useState(false);
  const [showEleven, setShowEleven] = useState(false);
  const [showTwelve, setShowTwelve] = useState(false);
  const [showThirteen, setShowThirteen] = useState(false);
  const [showFourteen, setShowFourteen] = useState(false);
  const [showFifteen, setShowFifteen] = useState(false);

  const [speakerFade, setSpeakerFade] = useState(true)
  useEffect(() => {
    const timeId = setTimeout(() => {
      setSpeakerFade(false)
    }, 10000)
    return () => {
      clearTimeout(timeId)
    }
  }, []);

  const [unmountSpeaker, setUnmountSpeaker] = useState(true)
  useEffect(() => {
    const timer = setTimeout(() => {
      setUnmountSpeaker(false)
    }, 20000)
    return () => {
      clearTimeout(timer)
    }
  }, []);

  const [chatIsActive, setChatActive] = useState(false);
  const activateChat = () => {
    setChatActive(!chatIsActive)
    sendDataToParent(chatIsActive);
  };

  const [slideChanged, setSlideChanged] = useState(false)
  useEffect(() => {
    const timeId = setTimeout(() => {
      setSlideChanged(true)
    }, 1000)
    return () => {
      clearTimeout(timeId)
    }
  }, []);

  const [chatFade, setChatFade] = useState(false)
  useEffect(() => {
    const timeId = setTimeout(() => {
      setChatFade(true)
    }, 2000)
    return () => {
      clearTimeout(timeId)
    }
  }, []);

  let fromProps = {
		style: { animation: `fadeIn 0.7s` },
	};
  const bottomRef = useRef(null);
  const mobilBottomRef = useRef(null);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    bottomRef.current?.scrollIntoView({behavior: 'smooth'});
    // eslint-disable-next-line no-unused-expressions
    mobilBottomRef.current?.scrollIntoView({behavior: 'smooth'});
  }, [showOne, showTwo, showThree, showFour, showFive, showSix, showSeven, showEight, showNine, showTen, showEleven, showTwelve, showThirteen, showFourteen, showFifteen]);

  return (
    <>

      <Vimeo 
        ref={player}
        className={styles.vimeoVideo}
        video={'https://vimeo.com/823475114/1ef7c41ee3'}
        controls={false}
        autoPlay={false}
        loop={true}
        playsInline={true}
        paused={playing ? false : true}
        playing={playing ? true : false}
        muted={false}
        autopause={true}
        onPlaying={() => {
          setPlaying(true)
        }}
        onTimeUpdate={(progress) => {
          setPlayed(progress.seconds)
          setProgress(progress.percent)
          const currentTime = progress.seconds;
          // console.log(progress, currentTime)
          if (currentTime >= '03') {
            setShowOne(true)
          } 
          if (currentTime >= '09') {
            setShowTwo(true)
          }
          if (currentTime >= '15') {
            setShowThree(true);
          }
          if (currentTime >= '30') {
            setShowFour(true);
          }
          if (currentTime >= '45') {
            setShowFive(true);
          }
          if (currentTime >= '50') {
            setShowSix(true);
          }
          if (currentTime >= '60') {
            setShowSeven(true);
          }
          if (currentTime >= '75') {
            setShowEight(true);
          }
          if (currentTime >= '90') {
            setShowNine(true);
          }
          if (currentTime >= '120') {
            setShowTen(true);
          }
          if (currentTime >= '127') {
            setShowEleven(true);
          }
          if (currentTime >= '152') {
            setShowTwelve(true);
          }
          if (currentTime >= '170') {
            setShowThirteen(true);
          }
          if (currentTime >= '182') {
            setShowFourteen(true);
          }
          if (currentTime >= '197') {
            setShowFifteen(true);
          }
        }}
      />
      <ProgressBar playing={playing} completed={prog * 100} />
      
        {isActive && slideChanged && transitionEnded &&
          <div style={{ opacity: chatFade ? '1' : '0', transition: '0.3s'}} className={styles.chatContainer}>
            <div className={`${styles.chatsMobile}`}>
        
          {!toggleInfo && modalOpen && !chatIsActive &&
            <div className={styles.soundOn}
              style={{
                position: 'absolute',
                bottom: '0',
                left: '0',
                right: '0',
                margin: 'auto',
                transition: "all .6s ease-in-out",
                opacity: speakerFade ? 1 : 0,
              }}
              >
              <img src={speaker} alt="speaker" /><p>sound on for Best Experience</p>
            </div>
        }
            <button onClick={activateChat} className={`${styles.chatButton} ${chatIsActive ? styles.active : ''}`}>
              <Quote className={chatIsActive ? styles.active : ''} />
            </button>
            <div 
              style={{
                height: '100%',
                opacity: chatIsActive ? '1' : '0'
                }} 
                className={styles.chatBlurContainer}>
              <div className={styles.chatContainerInner}>
              {/* {chatIsActive &&  */}
                <>
                  {showOne && 
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Organ by paul_cine</h5>
                      <h6>0:03</h6>
                    </div>
                  }
                  {showTwo && 
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Lazerbeak</h5>
                      <h6>0:09</h6>
                      <p>I wanted to start this remix out with as soft, honest, and vulnerable of a musical feeling as possible to directly tie into the theme of radical honesty.</p>
                    </div>
                  }
                  {showThree &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>SYNTH by Firephly</h5>
                      <h6>0:15</h6>
                    </div>
                  }
                  {showFour &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>keys by TheVoidInclusive</h5>
                      <h6>0:30</h6>
                    </div>
                  }
                  {showFive &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Guitar by TheVoidInclusive</h5>
                      <h6>0:45</h6>
                    </div>
                  }
                  {showSix && 
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Firephly</h5>
                      <h6>0:50</h6>
                      <p>Awww man this is giving me the feels!</p>
                    </div>
                  }
                  {showSeven &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Keys by TheVoidInclusive</h5>
                      <h6>1:00</h6>
                    </div>
                  }
                  {showEight &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Synth by applejaxsx</h5>
                      <h6>1:15</h6>
                    </div>
                  }
                  {showNine &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Drum Loop by fancyspectacles</h5>
                      <h6>1:30</h6>
                    </div>
                  }
                  {showTen &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Guitar by FeloniousMonk</h5>
                      <h6>2:00</h6>
                    </div>
                  }
                  {showEleven &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Lazerbeak</h5>
                      <h6>2:07</h6>
                      <p>I wanted to give the listener a moment to catch their breath here - highlighting this awesome guitar line and how it interacts with the drum break felt really natural.</p>
                    </div>
                  }
                  {showTwelve &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Shaker by MNG</h5>
                      <h6>2:32</h6>
                    </div>
                  }
                  {showThirteen &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Lazerbeak</h5>
                      <h6>2:50</h6>
                      <p>All the stems that the Endlesss community generated for this track felt so epic and triumphant that I wanted to take things fully over the top for the big ending.</p>
                    </div>
                  }
                  {showFourteen &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Lazerbeak</h5>
                      <h6>3:02</h6>
                      <p>Nothing screams triumph like a choir of brass instruments, so I chopped up some additional horn samples to layer on top for the grand finale. It felt really connecting to add an element of my own onto the stems I was given - adding my twist into the jam just like the Endlesss folks had done before me.</p>
                    </div>
                  }
                  {showFifteen &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Oddstar</h5>
                      <h6>3:18</h6>
                      <p>They make for a really cool accent in the finale.</p>
                    </div>
                  }
                  <div ref={mobilBottomRef} />
                </>
              {/* } */}
              </div>
            </div>
        </div>
      
        <div className={`${styles.chatsDesktop}`}>
          <div className={styles.chatBlurContainer}>
            <div className={styles.chatContainerInner}>
            {!toggleInfo && modalOpen &&
            <>
              {unmountSpeaker &&
                <div className={styles.chatBox} 
                style={{
                  opacity: speakerFade ? 1 : 0,
                  justifyContent: 'center',
                  // height: speakerFade ? 'auto' : '0px',
                  transition: "opacity .6s ease-in-out",
                }} >
                  <img src={speaker} alt="speaker" />
                  <h5>sound on for Best Experience</h5>
                </div>
              }
            </>
            }
              {showOne && 
                <div {...fromProps} className={styles.chatBox}>
                  <h5>Organ by paul_cine</h5>
                  <h6>0:03</h6>
                </div>
              }
              {showTwo && 
                <div {...fromProps} className={styles.chatBox}>
                  <h5>Lazerbeak</h5>
                  <h6>0:09</h6>
                  <p>I wanted to start this remix out with as soft, honest, and vulnerable of a musical feeling as possible to directly tie into the theme of radical honesty.</p>
                </div>
              }
              {showThree &&
                <div {...fromProps} className={styles.chatBox}>
                  <h5>SYNTH by Firephly</h5>
                  <h6>0:15</h6>
                </div>
              }
              {showFour &&
                <div {...fromProps} className={styles.chatBox}>
                  <h5>keys by TheVoidInclusive</h5>
                  <h6>0:30</h6>
                </div>
              }
              {showFive &&
                <div {...fromProps} className={styles.chatBox}>
                  <h5>Guitar by TheVoidInclusive</h5>
                  <h6>0:45</h6>
                </div>
              }
              {showSix && 
                <div {...fromProps} className={styles.chatBox}>
                  <h5>Firephly</h5>
                  <h6>0:50</h6>
                  <p>Awww man this is giving me the feels!</p>
                </div>
              }
              {showSeven &&
                <div {...fromProps} className={styles.chatBox}>
                  <h5>Keys by TheVoidInclusive</h5>
                  <h6>1:00</h6>
                </div>
              }
              {showEight &&
                <div {...fromProps} className={styles.chatBox}>
                  <h5>Synth by applejaxsx</h5>
                  <h6>1:15</h6>
                </div>
              }
              {showNine &&
                <div {...fromProps} className={styles.chatBox}>
                  <h5>Drum Loop by fancyspectacles</h5>
                  <h6>1:30</h6>
                </div>
              }
              {showTen &&
                <div {...fromProps} className={styles.chatBox}>
                  <h5>Guitar by FeloniousMonk</h5>
                  <h6>2:00</h6>
                </div>
              }
              {showEleven &&
                <div {...fromProps} className={styles.chatBox}>
                  <h5>Lazerbeak</h5>
                  <h6>2:07</h6>
                  <p>I wanted to give the listener a moment to catch their breath here - highlighting this awesome guitar line and how it interacts with the drum break felt really natural.</p>
                </div>
              }
              {showTwelve &&
                <div {...fromProps} className={styles.chatBox}>
                  <h5>Shaker by MNG</h5>
                  <h6>2:32</h6>
                </div>
              }
              {showThirteen &&
                <div {...fromProps} className={styles.chatBox}>
                  <h5>Lazerbeak</h5>
                  <h6>2:50</h6>
                  <p>All the stems that the Endlesss community generated for this track felt so epic and triumphant that I wanted to take things fully over the top for the big ending.</p>
                </div>
              }
              {showFourteen &&
                <div {...fromProps} className={styles.chatBox}>
                  <h5>Lazerbeak</h5>
                  <h6>3:02</h6>
                  <p>Nothing screams triumph like a choir of brass instruments, so I chopped up some additional horn samples to layer on top for the grand finale. It felt really connecting to add an element of my own onto the stems I was given - adding my twist into the jam just like the Endlesss folks had done before me.</p>
                </div>
              }
              {showFifteen &&
                <div {...fromProps} className={styles.chatBox}>
                  <h5>Oddstar</h5>
                  <h6>3:18</h6>
                  <p>They make for a really cool accent in the finale.</p>
                </div>
              }

              
              <div ref={bottomRef} />
            </div>
          </div>
        </div>
          </div>
        }

    </>
  );
});
export default RadicalVimeo;