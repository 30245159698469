import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import Vimeo from '@u-wave/react-vimeo'
import styles from '../endlesss.module.scss'

import Quote from "./quote"

import ProgressBar from './progressBar'
import './useFade.css'

import speaker from '../../../../images/athena-04-resilience/endlesss/speaker.png'

const HumanVimeo = forwardRef((props, humanVideo) => {
  useImperativeHandle(humanVideo, () => ({
    startPlaying() {
      setPlaying(true)
    },
    pauseVid() {
      setPlaying(false)
    },
    resumeVid() {
      setPlaying(true)
    },
    stopPlaying() {
      setPlaying(false)
    }
  }))

  const {toggleInfo, modalOpen, sendDataToParent, isActive, transitionEnded} = props;
  const player = useRef(null);

  const [playing, setPlaying] = useState(false);
  const [played, setPlayed] = useState(0);
  const [prog, setProgress] = useState(0);

  const [showOne, setShowOne] = useState(false);
  const [showTwo, setShowTwo] = useState(false);
  const [showThree, setShowThree] = useState(false);
  const [showFour, setShowFour] = useState(false);
  const [showFive, setShowFive] = useState(false);
  const [showSix, setShowSix] = useState(false);
  const [showSeven, setShowSeven] = useState(false);
  const [showEight, setShowEight] = useState(false);
  const [showNine, setShowNine] = useState(false);
  const [showTen, setShowTen] = useState(false);
  const [showEleven, setShowEleven] = useState(false);
  const [showTwelve, setShowTwelve] = useState(false);
  const [showThirteen, setShowThirteen] = useState(false);
  const [showFourteen, setShowFourteen] = useState(false);
  const [showFifteen, setShowFifteen] = useState(false);
  const [showSixteen, setShowSixteen] = useState(false);
  const [showSeventeen, setShowSeventeen] = useState(false);
  const [showEighteen, setShowEighteen] = useState(false);
  const [showNineteen, setShowNineteen] = useState(false);
  const [showTwenty, setShowTwenty] = useState(false);
  const [showTwentyOne, setShowTwentyOne] = useState(false);
  const [showTwentyTwo, setShowTwentyTwo] = useState(false);
  const [showTwentyThree, setShowTwentyThree] = useState(false);
  const [showTwentyFour, setShowTwentyFour] = useState(false);
  const [showTwentyFive, setShowTwentyFive] = useState(false);
  const [showTwentySix, setShowTwentySix] = useState(false);



  const [speakerFade, setSpeakerFade] = useState(true)
  useEffect(() => {
    const timeId = setTimeout(() => {
      setSpeakerFade(false)
    }, 10000)
    return () => {
      clearTimeout(timeId)
    }
  }, []);

  const [unmountSpeaker, setUnmountSpeaker] = useState(true)
  useEffect(() => {
    const timer = setTimeout(() => {
      setUnmountSpeaker(false)
    }, 20000)
    return () => {
      clearTimeout(timer)
    }
  }, []);


  const [slideChanged, setSlideChanged] = useState(false)
  useEffect(() => {
    const timeId = setTimeout(() => {
      setSlideChanged(true)
    }, 1000)
    return () => {
      clearTimeout(timeId)
    }
  }, []);

  const [chatFade, setChatFade] = useState(false)
  useEffect(() => {
    const timeId = setTimeout(() => {
      setChatFade(true)
    }, 2000)
    return () => {
      clearTimeout(timeId)
    }
  }, []);

  const [chatIsActive, setChatActive] = useState(false);
  const activateChat = () => {
    setChatActive(!chatIsActive)
    sendDataToParent(chatIsActive);
  };

  let fromProps = {
		style: { animation: `fadeIn 0.7s` },
	};
  const bottomRef = useRef(null);
  const mobilBottomRef = useRef(null);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    bottomRef.current?.scrollIntoView({behavior: 'smooth'});
    // eslint-disable-next-line no-unused-expressions
    mobilBottomRef.current?.scrollIntoView({behavior: 'smooth'});
  }, [showOne, showTwo, showThree, showFour, showFive, showSix, showSeven, showEight, showNine, showTen, showEleven, showTwelve, showThirteen, showFourteen, showFifteen, showSixteen, showSeventeen, showEighteen, showNineteen, showTwenty, showTwentyOne, showTwentyTwo, showTwentyThree, showTwentyFour, showTwentyFive, showTwentySix]);

  return (
    <>

      <Vimeo 
        ref={player}
        className={styles.vimeoVideo}
        video={'https://vimeo.com/834431673/ffdc9d8bb5'}
        // video={isActive ? 'https://vimeo.com/834431673/ffdc9d8bb5' : ''}
        controls={false}
        autoPlay={false}
        loop={true}
        playsInline={true}
        paused={playing ? false : true}
        playing={playing ? true : false}
        muted={false}
        autopause={true}
        onPlaying={() => {
          setPlaying(true)
        }}
        onTimeUpdate={(progress) => {
          setPlayed(progress.seconds)
          setProgress(progress.percent)
          const currentTime = progress.seconds;
          // console.log(progress, currentTime)
          if (currentTime >= '00') {
            setShowOne(true)
          } 
          if (currentTime >= '05') {
            setShowTwo(true)
          }
          if (currentTime >= '10') {
            setShowThree(true);
          }
          if (currentTime >= '11') {
            setShowFour(true);
          }
          if (currentTime >= '15') {
            setShowFive(true);
          }
          if (currentTime >= '22') {
            setShowSix(true);
          }
          if (currentTime >= '30') {
            setShowSeven(true);
          }
          if (currentTime >= '35') {
            setShowEight(true);
          }
          if (currentTime >= '41') {
            setShowNine(true);
          }
          if (currentTime >= '50') {
            setShowTen(true);
          }
          if (currentTime >= '52') {
            setShowEleven(true);
          }
          if (currentTime >= '56') {
            setShowTwelve(true);
          }
          if (currentTime >= '70') {
            setShowThirteen(true);
          }
          if (currentTime >= '72') {
            setShowFourteen(true);
          }
          if (currentTime >= '75') {
            setShowFifteen(true);
          }
          if (currentTime >= '85') {
            setShowSixteen(true);
          }

          if (currentTime >= '90') {
            setShowSeventeen(true);
          }
          if (currentTime >= '92') {
            setShowEighteen(true);
          }
          if (currentTime >= '95') {
            setShowNineteen(true);
          }
          if (currentTime >= '100') {
            setShowTwenty(true);
          }
          if (currentTime >= '120') {
            setShowTwentyOne(true);
          }
          if (currentTime >= '130') {
            setShowTwentyTwo(true);
          }
          if (currentTime >= '150') {
            setShowTwentyThree(true);
          }
          if (currentTime >= '170') {
            setShowTwentyFour(true);
          }
          if (currentTime >= '190') {
            setShowTwentyFive(true);
          }
          if (currentTime >= '200') {
            setShowTwentySix(true);
          }
        }}
      />
      <ProgressBar playing={playing} completed={prog * 100} />
      
      {isActive && slideChanged && transitionEnded &&
        <div style={{ opacity: chatFade ? '1' : '0', transition: '0.3s'}} className={styles.chatContainer}>
          <div className={`${styles.chatsMobile}`}>
            {!toggleInfo && modalOpen && !chatIsActive &&
              <div className={styles.soundOn}
                style={{
                  position: 'absolute',
                  bottom: '0',
                  left: '0',
                  right: '0',
                  margin: 'auto',
                  transition: "all .6s ease-in-out",
                  opacity: speakerFade ? 1 : 0,
                }}
                >
                <img src={speaker} alt="speaker" /><p>sound on for Best Experience</p>
              </div>
            }
            <button onClick={activateChat} className={`${styles.chatButton} ${chatIsActive ? styles.active : ''}`}>
            <Quote className={chatIsActive ? styles.active : ''} />
            </button>
            <div 
              style={{
                height: '100%',
                opacity: chatIsActive ? '1' : '0'
                }} 
                className={styles.chatBlurContainer}>
              <div className={styles.chatContainerInner}>
              {/* {chatIsActive &&  */}
                <>
                  {showOne && 
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Organ by paul_cine</h5>
                      <h6>0:00</h6>
                    </div>
                  }
                  {showTwo && 
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Percussion By mng</h5>
                      <h6>0:05</h6>
                    </div>
                  }
                  {showThree &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Chords By katinkera</h5>
                      <h6>0:10</h6>
                    </div>
                  }
                  {showFour &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Synth Delay Blips By sonicadventure</h5>
                      <h6>0:10</h6>
                    </div>
                  }
                  {showFive &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>tambourine By paul_cine</h5>
                      <h6>0:15</h6>
                    </div>
                  }
                  {showSix && 
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Claps By katinkera</h5>
                      <h6>0:22</h6>
                    </div>
                  }
                  {showSeven &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Atmospheric Drone By thevoidinclusive</h5>
                      <h6>0:30</h6>
                    </div>
                  }
                  {showEight &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Lazerbeak</h5>
                      <h6>0:35</h6>
                      <p>I had a blast with this one. The initial stems I pulled had such a dreamy and ethereal quality. Wanted to make the whole track feel like a breezy summer day.</p>
                    </div>
                  }
                  {showNine &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Plinky Piano Keys By paul_cine</h5>
                      <h6>0:41</h6>
                    </div>
                  }
                  {showTen &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Guitar By feloniousmonk</h5>
                      <h6>0:50</h6>
                    </div>
                  }
                  {showEleven &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Percussion by paul_cine</h5>
                      <h6>0:52</h6>
                    </div>
                  }
                  {showTwelve &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Keys By katinkera</h5>
                      <h6>0:56</h6>
                    </div>
                  }
                  {showThirteen &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Synth By awro</h5>
                      <h6>1:10</h6>
                    </div>
                  }
                  {showFourteen &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Guitar By feloniousmonk</h5>
                      <h6>1:12</h6>
                    </div>
                  }
                  {showFifteen &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Vocals By feloniousmonk</h5>
                      <h6>1:15</h6>
                    </div>
                  }
                  {showSixteen &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Rhodes By paul_cine</h5>
                      <h6>1:25</h6>
                    </div>
                  }
                  {showSeventeen &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Organ and Vocals By katinkera</h5>
                      <h6>1:30</h6>
                    </div>
                  }
                  {showEighteen &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Drum Loop By feloniousmonk</h5>
                      <h6>1:32</h6>
                    </div>
                  }
                  {showNineteen &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Percussion By paul_cine</h5>
                      <h6>1:35</h6>
                    </div>
                  }
                  {showTwenty &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Bitcrushed Percussion By ashtonmaxwell</h5>
                      <h6>1:40</h6>
                    </div>
                  }
                  {showTwentyOne &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Lazerbeak</h5>
                      <h6>2:00</h6>
                      <p>There were three unique vocal stems that I loved, and I wanted to highlight each one with its own section of the song. The lyrics all just magically happened to work well next to each other.</p>
                    </div>
                  }
                  {showTwentyTwo &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Vocals By katinkera</h5>
                      <h6>2:10</h6>
                    </div>
                  }
                  {showTwentyThree &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Vocals By fry</h5>
                      <h6>2:30</h6>
                    </div>
                  }

                  {showTwentyFour &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Guitar By Applejaxsx</h5>
                      <h6>2:50</h6>
                    </div>
                  }
                  {showTwentyFive &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Lazerbeak</h5>
                      <h6>3:10</h6>
                      <p>The biggest challenge with this ending was how to get all the vocals to weave around each other in a round—while still highlighting the instruments. I carved out a lot of space throughout the song and tried to tease out individual elements so that at the end it all gels together in one big symphony.</p>
                    </div>
                  }
                  {showTwentySix &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Oddstar</h5>
                      <h6>3:20</h6>
                      <p>This is terrific! Really great job of weaving the vocals in.</p>
                    </div>
                  }
                  <div ref={mobilBottomRef} />
                </>
              {/* } */}
              </div>
            </div>
          </div>
          <div className={`${styles.chatContainer} ${styles.chatsDesktop}`}>
          <div className={styles.chatBlurContainer}>
            <div className={styles.chatContainerInner}>
            {!toggleInfo && modalOpen &&
            <>
              {unmountSpeaker &&
                <div className={styles.chatBox} 
                style={{
                  opacity: speakerFade ? 1 : 0,
                  justifyContent: 'center',
                  // height: speakerFade ? 'auto' : '0px',
                  transition: "opacity .6s ease-in-out",
                }} >
                  <img src={speaker} alt="speaker" />
                  <h5>sound on for Best Experience</h5>
                </div>
              }
            </>
            }
              {showOne && 
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Organ by paul_cine</h5>
                      <h6>0:00</h6>
                    </div>
                  }
                  {showTwo && 
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Percussion By mng</h5>
                      <h6>0:05</h6>
                    </div>
                  }
                  {showThree &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Chords By katinkera</h5>
                      <h6>0:10</h6>
                    </div>
                  }
                  {showFour &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Synth Delay Blips By sonicadventure</h5>
                      <h6>0:10</h6>
                    </div>
                  }
                  {showFive &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>tambourine By paul_cine</h5>
                      <h6>0:15</h6>
                    </div>
                  }
                  {showSix && 
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Claps By katinkera</h5>
                      <h6>0:22</h6>
                    </div>
                  }
                  {showSeven &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Atmospheric Drone By thevoidinclusive</h5>
                      <h6>0:30</h6>
                    </div>
                  }
                  {showEight &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Lazerbeak</h5>
                      <h6>0:35</h6>
                      <p>I had a blast with this one. The initial stems I pulled had such a dreamy and ethereal quality. Wanted to make the whole track feel like a breezy summer day.</p>
                    </div>
                  }
                  {showNine &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Plinky Piano Keys By paul_cine</h5>
                      <h6>0:41</h6>
                    </div>
                  }
                  {showTen &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Guitar By feloniousmonk</h5>
                      <h6>0:50</h6>
                    </div>
                  }
                  {showEleven &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Percussion By paul_cine</h5>
                      <h6>0:52</h6>
                    </div>
                  }
                  {showTwelve &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Keys By katinkera</h5>
                      <h6>0:56</h6>
                    </div>
                  }
                  {showThirteen &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Synth By awro</h5>
                      <h6>1:10</h6>
                    </div>
                  }
                  {showFourteen &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Guitar By feloniousmonk</h5>
                      <h6>1:12</h6>
                    </div>
                  }
                  {showFifteen &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Vocals By feloniousmonk</h5>
                      <h6>1:15</h6>
                    </div>
                  }
                  {showSixteen &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Rhodes By paul_cine</h5>
                      <h6>1:25</h6>
                    </div>
                  }
                  {showSeventeen &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Organ and Vocals By katinkera</h5>
                      <h6>1:30</h6>
                    </div>
                  }
                  {showEighteen &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Drum Loop By feloniousmonk</h5>
                      <h6>1:32</h6>
                    </div>
                  }
                  {showNineteen &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Percussion By paul_cine</h5>
                      <h6>1:35</h6>
                    </div>
                  }
                  {showTwenty &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Bitcrushed Percussion By ashtonmaxwell</h5>
                      <h6>1:40</h6>
                    </div>
                  }
                  {showTwentyOne &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Lazerbeak</h5>
                      <h6>2:00</h6>
                      <p>There were three unique vocal stems that I loved, and I wanted to highlight each one with its own section of the song. The lyrics all just magically happened to work well next to each other.</p>
                    </div>
                  }
                  {showTwentyTwo &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Vocals By katinkera</h5>
                      <h6>2:10</h6>
                    </div>
                  }
                  {showTwentyThree &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Vocals By fry</h5>
                      <h6>2:30</h6>
                    </div>
                  }

                  {showTwentyFour &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Guitar By Applejaxsx</h5>
                      <h6>2:50</h6>
                    </div>
                  }
                  {showTwentyFive &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Lazerbeak</h5>
                      <h6>3:10</h6>
                      <p>The biggest challenge with this ending was how to get all the vocals to weave around each other in a round—while still highlighting the instruments. I carved out a lot of space throughout the song and tried to tease out individual elements so that at the end it all gels together in one big symphony.</p>
                    </div>
                  }
                  {showTwentySix &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Oddstar</h5>
                      <h6>3:20</h6>
                      <p>This is terrific! Really great job of weaving the vocals in.</p>
                    </div>
                  }
              
              <div ref={bottomRef} />
            </div>
          </div>
          </div>
        </div>
      }
    </>
  );
});
export default HumanVimeo;
