import React from "react"

const Quote = () => {
  return (
    <>
      <svg
        className="quote"
        width="60px"
        height="55px"
        viewBox="-4 -3 100 100"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="quote-icon"
        role="img"
      >
        <title id="quote-icon">Quote</title>
        <path d="m28.62,0H8.49C3.8,0,0,3.8,0,8.49v13.15c0,4.69,3.8,8.49,8.49,8.49h10.07l9.63,8.93.44-8.93c4.69,0,8.49-3.8,8.49-8.49v-13.15c0-4.69-3.8-8.49-8.49-8.49Zm-10.83,11.37c-3.84-.7-3.63,1.4-3.63,1.4v3.12h3.28v6.23h-6.56v-9.49c0-4.33,6.91-3.91,6.91-3.91v2.65Zm8.93,0c-3.84-.7-3.63,1.4-3.63,1.4v3.12h3.28v6.23h-6.56v-9.49c0-4.33,6.91-3.91,6.91-3.91v2.65Z"/>
      </svg>
    </>
  )
}

export default Quote
