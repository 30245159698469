import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect } from 'react';
import Vimeo from '@u-wave/react-vimeo'
import styles from '../endlesss.module.scss'

import Quote from "./quote"

import ProgressBar from './progressBar'
import './useFade.css'


import speaker from '../../../../images/athena-04-resilience/endlesss/speaker.png'

const PlayVimeo = forwardRef((props, playVideo) => {
  useImperativeHandle(playVideo, () => ({
    startPlaying() {
      setPlaying(true)
    },
    pauseVid() {
      setPlaying(false)
    },
    resumeVid() {
      setPlaying(true)
    },
    stopPlaying() {
      setPlaying(false)
    }
  }))


  const {toggleInfo, modalOpen, sendDataToParent, isActive, transitionEnded} = props;
  const playPlayer = useRef(null);

  const [playing, setPlaying] = useState(false);
  const [played, setPlayPlayed] = useState(0);
  const [prog, setProgress] = useState(0);
  
  const [showOne, setShowOne] = useState(false);
  const [showTwo, setShowTwo] = useState(false);
  const [showThree, setShowThree] = useState(false);
  const [showFour, setShowFour] = useState(false);
  const [showFive, setShowFive] = useState(false);
  const [showSix, setShowSix] = useState(false);
  const [showSeven, setShowSeven] = useState(false);
  const [showEight, setShowEight] = useState(false);
  const [showNine, setShowNine] = useState(false);
  const [showTen, setShowTen] = useState(false);
  const [showEleven, setShowEleven] = useState(false);
  const [showTwelve, setShowTwelve] = useState(false);
  const [showThirteen, setShowThirteen] = useState(false);
  const [showFourteen, setShowFourteen] = useState(false);
  const [showFifteen, setShowFifteen] = useState(false);

    const [showSixteen, setShowSixteen] = useState(false);
  const [showSeventeen, setShowSeventeen] = useState(false);
    const [showEighteen, setShowEighteen] = useState(false);
  const [showNineteen, setShowNineteen] = useState(false);
    const [showTwenty, setShowTwenty] = useState(false);
  const [showTwentyOne, setShowTwentyOne] = useState(false);
    const [showTwentyTwo, setShowTwentyTwo] = useState(false);
  const [showTwentyThree, setShowTwentyThree] = useState(false);

  const [speakerFade, setSpeakerFade] = useState(true)
  useEffect(() => {
    const timeId = setTimeout(() => {
      setSpeakerFade(false)
    }, 10000)
    return () => {
      clearTimeout(timeId)
    }
  }, []);

  const [unmountSpeaker, setUnmountSpeaker] = useState(true)
  useEffect(() => {
    const timer = setTimeout(() => {
      setUnmountSpeaker(false)
    }, 20000)
    return () => {
      clearTimeout(timer)
    }
  }, []);
  

  const [chatIsActive, setChatActive] = useState(false);
  const activateChat = () => {
    setChatActive(!chatIsActive)
    sendDataToParent(chatIsActive);
  };

  const [slideChanged, setSlideChanged] = useState(false)
  useEffect(() => {
    const timeId = setTimeout(() => {
      setSlideChanged(true)
    }, 1000)
    return () => {
      clearTimeout(timeId)
    }
  }, []);

  const [chatFade, setChatFade] = useState(false)
  useEffect(() => {
    const timeId = setTimeout(() => {
      setChatFade(true)
    }, 2000)
    return () => {
      clearTimeout(timeId)
    }
  }, []);


  let fromProps = {
		style: { animation: `fadeIn 0.7s` },
	};
  const bottomRef = useRef(null);
  const mobilBottomRef = useRef(null);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    bottomRef.current?.scrollIntoView({behavior: 'smooth'});
    // eslint-disable-next-line no-unused-expressions
    mobilBottomRef.current?.scrollIntoView({behavior: 'smooth'});
  }, [showOne, showTwo, showThree, showFour, showFive, showSix, showSeven, showEight, showNine, showTen, showEleven, showTwelve, showThirteen, showFourteen, showFifteen, showSixteen, showSeventeen, showEighteen, showNineteen, showTwenty, showTwentyOne, showTwentyTwo, showTwentyThree]);

  return (
    <>
      <Vimeo 
        ref={playPlayer}
        className={styles.vimeoVideo}
        video={'https://vimeo.com/831521223/d935c1b9e3'}
        controls={false}
        autoPlay={false}
        loop={true}
        playsInline={true}
        paused={playing ? false : true}
        playing={playing ? true : false}
        muted={false}
        autopause={true}
        onPlaying={() => {
          setPlaying(true)
        }}
        onTimeUpdate={(progress) => {
          setPlayPlayed(progress.seconds)
          setProgress(progress.percent)
          const currentTime = progress.seconds;
          if (currentTime >= '02') {
            setShowOne(true)
          } 
          if (currentTime >= '04') {
            setShowTwo(true)
          }
          if (currentTime >= '06') {
            setShowThree(true);
          }
          if (currentTime >= '08') {
            setShowFour(true);
          }
          if (currentTime >= '12') {
            setShowFive(true);
          }
          if (currentTime >= '16') {
            setShowSix(true);
          }
          if (currentTime >= '17') {
            setShowSeven(true);
          }
          if (currentTime >= '18') {
            setShowEight(true);
          }
          if (currentTime >= '19') {
            setShowNine(true);
          }
          if (currentTime >= '25') {
            setShowTen(true);
          }
          if (currentTime >= '32') {
            setShowEleven(true);
          }
          if (currentTime >= '35') {
            setShowTwelve(true);
          }
          if (currentTime >= '48') {
            setShowThirteen(true);
          }
          if (currentTime >= '49') {
            setShowFourteen(true);
          }
          if (currentTime >= '50') {
            setShowFifteen(true);
          }
          if (currentTime >= '64') {
            setShowSixteen(true);
          }
          if (currentTime >= '65') {
            setShowSeventeen(true);
          }
          if (currentTime >= '80') {
            setShowEighteen(true);
          }
          if (currentTime >= '112') {
            setShowNineteen(true);
          }
          if (currentTime >= '116') {
            setShowTwenty(true);
          }
          if (currentTime >= '130') {
            setShowTwentyOne(true);
          }
          if (currentTime >= '160') {
            setShowTwentyTwo(true);
          }
          if (currentTime >= '165') {
            setShowTwentyThree(true);
          }
        }}
      />
    
      <ProgressBar playing={playing} completed={prog * 100} />
      
      {isActive && slideChanged && transitionEnded &&
        <div style={{ opacity: chatFade ? '1' : '0', transition: '0.3s'}} className={styles.chatContainer}>
          <div className={`${styles.chatsMobile}`}>
            {!toggleInfo && modalOpen && !chatIsActive &&
              <div className={styles.soundOn}
                style={{
                  position: 'absolute',
                  bottom: '0',
                  left: '0',
                  right: '0',
                  margin: 'auto',
                  transition: "all .6s ease-in-out",
                  opacity: speakerFade ? 1 : 0,
                }}
                >
                <img src={speaker} alt="speaker" /><p>sound on for Best Experience</p>
              </div>
          }
              <button onClick={activateChat} className={`${styles.chatButton} ${chatIsActive ? styles.active : ''}`}>
                <Quote className={chatIsActive ? styles.active : ''} />
              </button>
              <div 
                style={{
                  height: '100%',
                  opacity: chatIsActive ? '1' : '0'
                  }} 
                  className={styles.chatBlurContainer}>
                <div className={styles.chatContainerInner}>
                  <>
                    {showOne && 
                      <div {...fromProps} className={styles.chatBox}>
                        <h5>Bongos By Badrico</h5>
                        <h6>0:02</h6>
                      </div>
                    }
                    {showTwo && 
                      <div {...fromProps} className={styles.chatBox}>
                        <h5>Percussion By Paul_Cine</h5>
                        <h6>0:04</h6>
                      </div>
                    }
                    {showThree &&
                      <div {...fromProps} className={styles.chatBox}>
                        <h5>FeloniousMonk</h5>
                        <h6>0:06</h6>
                        <p>That wood percussion at the start gives me old school vibes.</p>
                      </div>
                    }
                    {showFour &&
                      <div {...fromProps} className={styles.chatBox}>
                        <h5>Percussion By lwlkc</h5>
                        <h6>0:08</h6>
                      </div>
                    }
                    {showFive &&
                      <div {...fromProps} className={styles.chatBox}>
                        <h5>Percussion By Badrico</h5>
                        <h6>0:12</h6>
                      </div>
                    }
                    {showSix && 
                      <div {...fromProps} className={styles.chatBox}>
                        <h5>Beat By Firephly</h5>
                        <h6>0:16</h6>
                      </div>
                    }
                    {showSeven &&
                      <div {...fromProps} className={styles.chatBox}>
                        <h5>Kick By redskylullabye</h5>
                        <h6>0:17</h6>
                      </div>
                    }
                    {showEight &&
                      <div {...fromProps} className={styles.chatBox}>
                        <h5>Percussion FX By Littlewing</h5>
                        <h6>0:18</h6>
                      </div>
                    }
                    {showNine &&
                      <div {...fromProps} className={styles.chatBox}>
                        <h5>Guitar By Badrico</h5>
                        <h6>0:19</h6>
                      </div>
                    }
                    {showTen &&
                      <div {...fromProps} className={styles.chatBox}>
                        <h5>Lazerbeak</h5>
                        <h6>0:25</h6>
                        <p>This was actually the first remix I attempted for the project and I really wanted to highlight all the varied and different sounds I was pulling from the Endless community—hence the slow build in the intro and new elements dropping in every couple of seconds for the first few minutes of the track.</p>
                      </div>
                    }
                    {showEleven &&
                      <div {...fromProps} className={styles.chatBox}>
                        <h5>Synth By marky2coats</h5>
                        <h6>0:32</h6>
                      </div>
                    }
                    {showTwelve &&
                      <div {...fromProps} className={styles.chatBox}>
                        <h5>Synth By awro</h5>
                        <h6>0:35</h6>
                      </div>
                    }
                    {showThirteen &&
                      <div {...fromProps} className={styles.chatBox}>
                        <h5>Piano By thevoidinclusive</h5>
                        <h6>0:48</h6>
                      </div>
                    }
                    {showFourteen &&
                      <div {...fromProps} className={styles.chatBox}>
                        <h5>Synth By Firephly</h5>
                        <h6>0:49</h6>
                      </div>
                    }
                    {showFifteen &&
                      <div {...fromProps} className={styles.chatBox}>
                        <h5>Bass By winkle89</h5>
                        <h6>0:50</h6>
                      </div>
                    }
                    {showSixteen &&
                      <div {...fromProps} className={styles.chatBox}>
                        <h5>Vocals By oddSTAR</h5>
                        <h6>1:04</h6>
                      </div>
                    }
                    {showSeventeen &&
                      <div {...fromProps} className={styles.chatBox}>
                        <h5>Synth By caerickson</h5>
                        <h6>1:05</h6>
                      </div>
                    }
                    {showEighteen &&
                      <div {...fromProps} className={styles.chatBox}>
                        <h5>Vocals By badrico</h5>
                        <h6>1:20</h6>
                      </div>
                    }
                    {showNineteen &&
                      <div {...fromProps} className={styles.chatBox}>
                        <h5>Bass By discopas</h5>
                        <h6>1:52</h6>
                      </div>
                    }
                    {showTwenty &&
                      <div {...fromProps} className={styles.chatBox}>
                        <h5>Lazerbeak</h5>
                        <h6>1:56</h6>
                        <p>This bass line grabbed me instantly and felt like a great little transition to get from the intro build into the breakdown of the song. It only shows up this one time, but I love it so much.</p>
                      </div>
                    }
                    {showTwentyOne &&
                      <div {...fromProps} className={styles.chatBox}>
                        <h5>Guitar By FeloniousMonk</h5>
                        <h6>2:10</h6>
                      </div>
                    }
                    {showTwentyTwo &&
                      <div {...fromProps} className={styles.chatBox}>
                        <h5>Lazerbeak</h5>
                        <h6>2:40</h6>
                        <p>I love building up a vibe slowly in a track and not revealing all the huge catchy elements until the very end of the song. This particular remix felt like the perfect opportunity to reward the listener's patience with a big and epic end section of vocals, flutes, electric guitars, and strings.</p>
                      </div>
                    }
                    {showTwentyThree &&
                      <div {...fromProps} className={styles.chatBox}>
                        <h5>Oddstar</h5>
                        <h6>2:45</h6>
                        <p>Good driving music! <span>🤩</span></p>
                      </div>
                    }

                    

                    <div ref={mobilBottomRef} />
                  </>
                </div>
              </div>
          </div>
          
          <div className={`${styles.chatsDesktop}`}>
          <div className={styles.chatBlurContainer}>
            <div className={styles.chatContainerInner}>
            {!toggleInfo && modalOpen &&
            <>
              {unmountSpeaker &&
                <div className={styles.chatBox} 
                style={{
                  opacity: speakerFade ? 1 : 0,
                  justifyContent: 'center',
                  // height: speakerFade ? 'auto' : '0px',
                  transition: "opacity .6s ease-in-out",
                }} >
                  <img src={speaker} alt="speaker" />
                  <h5>sound on for Best Experience</h5>
                </div>
              }
            </>
            }
                  {showOne && 
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Bongos By Badrico</h5>
                      <h6>0:02</h6>
                    </div>
                  }
                  {showTwo && 
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Percussion By Paul_Cine</h5>
                      <h6>0:04</h6>
                    </div>
                  }
                  {showThree &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>FeloniousMonk</h5>
                      <h6>0:06</h6>
                      <p>That wood percussion at the start gives me old school vibes.</p>
                    </div>
                  }
                  {showFour &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Percussion By lwlkc</h5>
                      <h6>0:08</h6>
                    </div>
                  }
                  {showFive &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Percussion By Badrico</h5>
                      <h6>0:12</h6>
                    </div>
                  }
                  {showSix && 
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Beat By Firephly</h5>
                      <h6>0:16</h6>
                    </div>
                  }
                  {showSeven &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Kick By redskylullabye</h5>
                      <h6>0:17</h6>
                    </div>
                  }
                  {showEight &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Percussion FX By Littlewing</h5>
                      <h6>0:18</h6>
                    </div>
                  }
                  {showNine &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Guitar By Badrico</h5>
                      <h6>0:19</h6>
                    </div>
                  }
                  {showTen &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Lazerbeak</h5>
                      <h6>0:25</h6>
                      <p>This was actually the first remix I attempted for the project and I really wanted to highlight all the varied and different sounds I was pulling from the Endless community—hence the slow build in the intro and new elements dropping in every couple of seconds for the first few minutes of the track.</p>
                    </div>
                  }
                  {showEleven &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Synth By marky2coats</h5>
                      <h6>0:32</h6>
                    </div>
                  }
                  {showTwelve &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Synth By awro</h5>
                      <h6>0:35</h6>
                    </div>
                  }
                  {showThirteen &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Piano By thevoidinclusive</h5>
                      <h6>0:48</h6>
                    </div>
                  }
                  {showFourteen &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Synth By Firephly</h5>
                      <h6>0:49</h6>
                    </div>
                  }
                  {showFifteen &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Bass By winkle89</h5>
                      <h6>0:50</h6>
                    </div>
                  }
                  {showSixteen &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Vocals By oddSTAR</h5>
                      <h6>1:04</h6>
                    </div>
                  }
                  {showSeventeen &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Synth By caerickson</h5>
                      <h6>1:05</h6>
                    </div>
                  }
                  {showEighteen &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Vocals By badrico</h5>
                      <h6>1:20</h6>
                    </div>
                  }
                  {showNineteen &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Bass By discopas</h5>
                      <h6>1:52</h6>
                    </div>
                  }
                  {showTwenty &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Lazerbeak</h5>
                      <h6>1:56</h6>
                      <p>This bass line grabbed me instantly and felt like a great little transition to get from the intro build into the breakdown of the song. It only shows up this one time, but I love it so much.</p>
                    </div>
                  }
                  {showTwentyOne &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Guitar By FeloniousMonk</h5>
                      <h6>2:10</h6>
                    </div>
                  }
                  {showTwentyTwo &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Lazerbeak</h5>
                      <h6>2:40</h6>
                      <p>I love building up a vibe slowly in a track and not revealing all the huge catchy elements until the very end of the song. This particular remix felt like the perfect opportunity to reward the listener's patience with a big and epic end section of vocals, flutes, electric guitars, and strings.</p>
                    </div>
                  }
                  {showTwentyThree &&
                    <div {...fromProps} className={styles.chatBox}>
                      <h5>Oddstar</h5>
                      <h6>2:45</h6>
                      <p>Good driving music! <span>🤩</span></p>
                    </div>
                  }

                  

              <div ref={bottomRef} />
            </div>
          </div>
          </div>
        </div>
      }
      
    </>
  );
})
export default PlayVimeo;