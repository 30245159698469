import React from "react";
import { useSpring, animated } from '@react-spring/web'
import styles from '../endlesss.module.scss'

const ProgressBar = (props) => {
  const {completed, playing } = props;

  const moveLeft = useSpring({
    from: { opacity: 0 },
    to: { 
      opacity: playing ? 0.5 : 0.25, 
      // transform: 'translateX(100%)',
      left: `${completed}%`,
      }
    }
  )


  return (
<>
<div className={styles.progressBar}>
    <animated.div className={styles.innerBar} style={moveLeft}>
      {completed}
      {playing ? 'playing' : 'paused'}
    </animated.div>
 </div>
    </>
  )};

export default ProgressBar;